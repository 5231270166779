@import 'open-color/open-color.css';
:root {
  --oc-white: #ffffff;
  --clock-container-width-l: 380px;
  --clock-container-width-m: 320px;
  --clock-container-width-s: 280px;
  --clock-container-width-xs: 220px;
  --clock-width-l: 320px;
  --clock-width-m: 260px;
  --clock-width-s: 200px;
  --clock-width-xs: 180px;
  --clock-height-l: 320px;
  --clock-height-m: 260px;
  --clock-height-s: 200px;
  --clock-height-xs: 180px;
}

.picky-date-time-clock__inline-div {
  display: inline-block;
}

.picky-date-time-clock__inline-div--middle {
  vertical-align: middle;
}

.picky-date-time-clock {
  margin: 0 auto;
  &.l {
    width: var(--clock-container-width-l);
  }
  &.m {
    width: var(--clock-container-width-m);
  }
  &.s {
    width: var(--clock-container-width-s);
  }
  &.xs {
    width: var(--clock-container-width-xs);
  }
}

.picky-date-time-clock__header {
}

.picky-date-time-clock__inputer-wrapper {
  margin-top: 30px;
  text-align: center;
}

.picky-date-time-clock__inputer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  text-align: center;
  margin-top: 3px;
  border: 1px solid var(--oc-gray-5);
  padding: 5px 10px;
  width: 65%;
}

.picky-date-time-clock__input {
  height: 20px;
  line-height: 20px;
  width: 100%;
  border: none;
  outline: 0;
  background: transparent;
  touch-action: none;
}

.picky-date-time-clock__input::-ms-clear {
  width: 0;
  height: 0;
}

.picky-date-time-clock__icon--refresh,
.picky-date-time-clock__icon--schedule,
.picky-date-time-clock__icon--remove_circle_outline {
  cursor: pointer;
  color: var(--oc-gray-6);
}

.picky-date-time-clock__icon--schedule {
}

.picky-date-time-clock__icon--remove_circle_outline {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.picky-date-time-clock__circle {
  margin: 0 auto;
  position: relative;
  border: 1px solid var(--oc-gray-5);
  border-radius: 50%;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 18px;
  &.l {
    width: var(--clock-width-l);
    height: var(--clock-height-l);
  }
  &.m {
    width: var(--clock-width-m);
    height: var(--clock-height-m);
  }
  &.s {
    width: var(--clock-width-s);
    height: var(--clock-height-s);
  }
  &.xs {
    width: var(--clock-width-xs);
    height: var(--clock-height-xs);
  }
}

.picky-date-time-clock__clock-minute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: var(--oc-gray-5);
  width: 1px;
}

.picky-date-time-clock__clock-minute--five {
  height: 3px;
  background: var(--oc-gray-7);
  width: 1px;
  &.picky-date-time-clock__clock-minute--quarter {
    height: 6px;
    background: var(--oc-gray-8);
    width: 1px;
  }
}

.picky-date-time-clock__clock-center,
.picky-date-time-clock__clock-hand {
  position: absolute;
  left: 50%;
  top: 50%;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: antialiased;
  transform-origin: 50% 50%;
}

.picky-date-time-clock__clock-center {
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--oc-white);
  border: 2px solid var(--oc-gray-6);
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.picky-date-time-clock__clock-hand--second {
  width: 2px;
  height: 70px;
  background: var(--oc-red-5);
  z-index: 3;
  cursor: move;
}

.picky-date-time-clock__clock-hand--minute {
  width: 2px;
  height: 65px;
  background: var(--oc-gray-6);
  z-index: 2;
  cursor: move;
}

.picky-date-time-clock__clock-hand--hour {
  width: 3px;
  height: 50px;
  background: var(--oc-gray-7);
  z-index: 1;
  cursor: move;
}

.picky-date-time-clock__today {
  font-size: 12px;
  margin-top: 10px;
}

.picky-date-time-clock__inline-span span {
  display: inline-block;
  vertical-align: middle;
}

.picky-date-time-clock__inline-span:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.picky-date-time-clock__today .picky-date-time-clock__icon {
  font-size: 15px;
}

.picky-date-time-clock__icon {
  font-size: 15px;
}

.picky-date-time-clock__button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  color: var(--oc-gray-6);
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid var(--oc-gray-4);
  transition: all 0.3s;
  background: var(--oc-white);
  &:hover {
    border: 1px solid var(--oc-blue-4);
    background: var(--oc-blue-4);
    color: var(--oc-white);
  }
}

@media only screen and (max-width: 550px) {
}

@import 'open-color/open-color.css';
@import './transition.css';
@import './calendar.css';
@import './clock.css';
[class*=' picky-date-time'],
[class^='picky-date-time'] {
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --oc-white: #ffffff;
  --breaker-padding-l: 0 5px;
  --breaker-padding-m: 0 5px;
  --breaker-padding-s: 0 5px;
  --breaker-padding-xs: 0 5px;
}

.picky-date-time {
  opacity: 0;
  position: relative;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.10), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  display: inline-block;
  transition: all 0.3s;
  transform: translateY(-1em) perspective(600px) rotateX(10deg);
  padding: 20px;
}

.picky-date-time.visible {
  opacity: 1;
  transform: translateY(0) perspective(600px) rotateX(0);
}

.picky-date-time__calendar,
.picky-date-time__clock {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 40px;
  position: relative;
}

.picky-date-time__calendar:before,
.picky-date-time__clock:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.picky-date-time__calendar {
}

.picky-date-time__clock {
}

.picky-date-time__close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--oc-gray-5);
}

.picky-date-time__breaker.l {
  padding: var(--breaker-padding-l);
}

.picky-date-time__breaker.m {
  padding: var(--breaker-padding-m);
}

.picky-date-time__breaker.s {
  padding: var(--breaker-padding-s);
}

.picky-date-time__breaker.xs {
  padding: var(--breaker-padding-xs);
}

@media only screen and (max-width: 550px) {
  .picky-date-time__calendar.xs,
  .picky-date-time__clock.xs {
    display: block;
  }
  .picky-date-time__breaker.xs {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .picky-date-time__calendar.s,
  .picky-date-time__clock.s {
    display: block;
  }
  .picky-date-time__breaker.s {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .picky-date-time__calendar.m,
  .picky-date-time__clock.m {
    display: block;
  }
  .picky-date-time__breaker.m {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .picky-date-time__calendar.l,
  .picky-date-time__clock.l {
    display: block;
  }
  .picky-date-time__breaker.l {
    display: none;
  }
}

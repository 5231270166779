@import 'open-color/open-color.css';
:root {
  --oc-white: #ffffff;
  --item-width-l: 60px;
  --item-width-m: 40px;
  --item-width-s: 25px;
  --item-width-xs: 25px;
  --item-height-l: 60px;
  --item-height-m: 40px;
  --item-height-s: 25px;
  --item-height-xs: 25px;
}

/* dropdown section */

.picky-date-time-dropdown {
  position: relative;
}

.picky-date-time-dropdown.visible .picky-date-time-dropdown-calendar__menu {
  transform: translate3d(-50%, 0, 0) scale3d(1, 1, 1);
  transform: translate(-50%, 0) scale(1, 1) \9;
  opacity: 1;
  padding: 10px;
  z-index: 1000;
}

.picky-date-time-dropdown.visible .picky-date-time-dropdown-calendar__menu-no-effect {
  display: block;
}

.picky-date-time-dropdown .picky-date-time-dropdown-calendar__menu {
  will-change: transform, opacity;
  transform: translate3d(-50%, 0, 0) scale3d(1, 0, 1);
  transform: translate(-50%, 0) scale(1, 0) \9;
  opacity: 0;
  left: 50%;
  &.l {
    width: 380px;
    margin-top: 40px;
  }
  &.m {
    width: 300px;
    margin-top: 35px;
  }
  &.s {
    width: 280px;
    margin-top: 30px;
  }
  &.xs {
    width: 280px;
    margin-top: 30px;
  }
  text-align: center;
  transform-origin: 0 0;
  transition: transform 0.4s, opacity 0.2s;
  position: absolute;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  /*z-index: -1;*/
  background: var(--oc-white);
}

.picky-date-time-dropdown .picky-date-time-dropdown-calendar__container {
  border-radius: 3px;
  overflow: hidden;
  margin-top: 10px;
}

.picky-date-time-dropdown .picky-date-time-dropdown-calendar__item {
  padding: 1px 4px;
  line-height: 20px;
  transition: background 0.4s;
  cursor: pointer;
  display: block;
}

.picky-date-time-dropdown-calendar__month {
  background: var(--oc-white);
  *zoom: 1;
  &:after {
    content: '\200B';
    display: block;
    height: 0;
    clear: both;
  }
}

.picky-date-time-dropdown-calendar__month-item {
  background: var(--oc-white);
  cursor: pointer;
  float: left;
  width: 33.3%;
}

.picky-date-time-dropdown-calendar__month-item > div {
  &.xs {
    padding: 10px 0;
  }
  padding: 10px 2px;
  font-size: 12px;
  margin: 5px;
  background: var(--oc-white);
  transition: all 0.3s;
  &:hover {
    background: var(--oc-blue-3);
    color: var(--oc-white);
  }
}

.picky-date-time-dropdown-calendar__month-item.active > div {
  background: var(--oc-blue-5);
  color: var(--oc-white);
}

.picky-date-time-calendar__previous,
.picky-date-time-calendar__next {
}

.picky-date-time-dropdown .picky-date-time-dropdown-calendar__item:hover {
  background: #eee;
}

.picky-date-time-dropdown-calendar__year {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: var(--oc-white);
}

.picky-date-time-dropdown-calendar__year-item {
  background: var(--oc-white);
  cursor: pointer;
  float: left;
  height: 100%;
  display: table;
  width: 20%;
}

.picky-date-time-dropdown-calendar__year-item > div {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  margin: 2px;
  font-size: 14px;
  background: var(--oc-white);
  transition: all 0.3s;
  &:hover {
    background: var(--oc-blue-3);
    color: var(--oc-white);
  }
}

.picky-date-time-dropdown-calendar__year-item.active > div {
  background: var(--oc-blue-5);
  color: var(--oc-white);
}

/* end of dropdown section */

.picky-date-time-calendar__default-day,
.picky-date-time-calendar__today {
  font-size: 12px;
  margin-top: 10px;
}

.picky-date-time-calendar__today {
  left: 0;
}

.picky-date-time-calendar__default-day {
  right: 0;
}

.picky-date-time-calendar__default-day .picky-date-time-calendar__icon,
.picky-date-time-calendar__today .picky-date-time-calendar__icon {
  font-size: 15px;
}

.picky-date-time-calendar__clicker {
  cursor: pointer;
}

.picky-date-time__col {
  display: inline-block;
  vertical-align: middle;
}

.picky-date-time-calendar__title {
  cursor: pointer;
  width: 100%;
  position: absolute;
  color: var(--oc-gray-8);
  &:hover {
    color: var(--oc-blue-3);
  }
}

.picky-date-time-calendar__inline-span span {
  display: inline-block;
  vertical-align: middle;
}

.picky-date-time-calendar__inline-span:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.picky-date-time-calendar__content {
}

.picky-date-time-calendar__icon {
  cursor: pointer;
  font-size: 20px;
}

.picky-date-time__col-0-5 {
  width: 5%;
}

.picky-date-time__col-9 {
  width: 90%;
}

.picky-date-time__col-3 {
  width: 25%;
}

.picky-date-time__col-6 {
  width: 50%;
}

.picky-date-time-calendar__header {
  text-align: center;
}

.picky-date-time--inline-block {
  display: inline-block;
  vertical-align: middle;
}

.picky-date-time-calendar__table {
  display: table;
  border-collapse: collapse;
  border-collapse: initial!important\9;
  margin: 0 auto;
}

@media all and (-ms-high-contrast: none) {
  .picky-date-time-calendar__table {
    border-collapse: initial;
  }
}

@supports (-ms-ime-align: auto) {
  .picky-date-time-calendar__table {
    border-collapse: initial;
  }
}

.picky-date-time-calendar__table-row {
  display: table-row;
}

.picky-date-time-calendar__table-cel {
  font-size: 12px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: 1px solid var(--oc-gray-2);
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: default;
  transition: all 0.3s;
  background: var(--oc-white);
  color: var(--oc-gray-7);
  fill: var(--oc-gray-7);
  & svg {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
  }
  &.l {
    font-size: 14px;
    width: var(--item-width-l);
    height: var(--item-height-l);
  }
  &.m {
    width: var(--item-width-m);
    height: var(--item-height-m);
  }
  &.s {
    width: var(--item-width-s);
    height: var(--item-height-s);
  }
  &.xs {
    padding: 6px;
    width: var(--item-width-xs);
    height: var(--item-height-xs);
  }
  &.disabled {
    color: var(--oc-gray-5);
    fill: var(--oc-gray-5);
  }
  &.today {
    color: var(--oc-red-5);
    fill: var(--oc-red-5);
  }
  &.marked {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      background-color: var(--oc-gray-4);
      border-radius: 50%;
      left: 50%;
      bottom: 3px;
      transform: translateX(-50%);
    }
  }
  &.active {
    &:not(.today) {
      color: var(--oc-white);
      fill: var(--oc-white);
      background: var(--oc-blue-3);
    }
  }
  &.no-border {
    border: 1px solid transparent;
  }
  &.picky-date-time-calendar__date-item {
    position: relative;
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        color: var(--oc-white);
        fill: var(--oc-white);
        background: var(--oc-blue-3);
      }
    }
  }
  &.picky-date-time-calendar__date-item .picky-date-time-calendar__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
  }
}

.picky-date-time-calendar__table-caption {
  color: var(--oc-gray-7);
}

.picky-date-time-calendar__mask {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  &.visible {
    opacity: 1 !important;
    filter: alpha(opacity=100);
    background-color: rgba(0, 0, 0, 0.3) !important;
    z-index: 1 !important;
  }
}

.picky-date-time-calendar__button {
  position: absolute;
  bottom: -40px;
  display: inline-block;
  color: var(--oc-gray-6);
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid var(--oc-gray-4);
  transition: all 0.3s;
  background: var(--oc-white);
  &:hover {
    border: 1px solid var(--oc-blue-4);
    background: var(--oc-blue-4);
    color: var(--oc-white);
  }
}
